html {
    background-color: #f8f9fa;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.card-body {
    overflow: hidden;
}

.card {
    min-height: 180px;
    max-height: 180px !important;
}

.card:not(.comment):hover {
    border-color: var(--bs-primary) !important;
}

.comment:hover {
    border-color: none !important;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1050;
    display: flex;
    align-items: center;
    justify-content: center;
}

.viewport-container {
    height: calc(100vh - 64px);
    position: relative;
}

.flow-background {
    --dot-bg: white;
    --dot-color: black;
    --dot-size: 1px;
    --dot-space: 20px;
    background:
        linear-gradient(
                90deg,
                var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
                transparent 1%
            )
            center / var(--dot-space) var(--dot-space),
        linear-gradient(
                var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
                transparent 1%
            )
            center / var(--dot-space) var(--dot-space),
        var(--dot-color);
}

.list-group-item {
    border: none !important;
    padding: 0 !important;
}

.form-check-input {
    border-color: var(--bs-secondary) !important;
}

.form-check-input:hover,
.form-check-input:not(:checked):focus {
    background-color: white !important;
    border-color: var(--bs-primary) !important;
}

.form-check-input:focus,
.form-check-input:active {
    border-color: var(--bs-secondary) !important;
    box-shadow: none !important;
}

.form-check-input:checked {
    border-color: var(--bs-primary) !important;
    background-color: var(--bs-primary) !important;
}

.extra-space {
    padding-left: 0.25rem;
}

.bg-custom-primary {
    background-color: #372344;
}

.bg-custom-primary-inverted {
    background-color: white;
    color: #372344 !important;
    border: 1px #372344 !important;
}

.btn-outline-primary {
    background-color: white !important;
}

.btn-outline-primary:hover {
    background-color: var(--bs-primary) !important;
    color: white !important;
}

.toast-body,
.toast-header {
    background-color: white !important;
}

.form-control {
    border-color: var(--bs-primary) !important;
}

.badge-small {
    font-size: 0.65em !important;
}

.over-img {
    height: 100%;
    width: auto;
}
